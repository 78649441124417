<template>
  <div>
    <a href="javascript:" @click="createSampleData">create sample data</a>
  </div>
</template>

<script>
import {fn} from '@/globals'

export default {
  name: "SampleData",
  components: {},
  data() {
    return {}
  },
  methods: {
    async createSampleData() {
      let createSampleDataFn = fn.httpsCallable("partnerWidget-createSampleData")
      let response = await createSampleDataFn({})
      console.log(response)
    },
  },
}
</script>

<style lang="less"></style>