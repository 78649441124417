<template>
  <div
    class="
      border-4 border-dashed border-gray-200
      rounded-lg
      h-96
      flex
      items-center
      justify-center
    "
  >
    <span v-if="!['tech@cuddle.me'].includes(auth.email)">nothing here yet, cuddle off.</span>
    <SampleData v-if="['tech@cuddle.me'].includes(auth.email)"/>
  </div>
</template>
e
<script>
import SampleData from "@/components/SampleData"

export default {
  name: "Settings",
  components: {
    SampleData,
  },
  computed: {
    auth() {
      return this.$store.state.auth.auth
    }
  }
}
</script>
